import React, { useEffect, useState } from "react";
import TableRow from "../components/TableRow/TableRow.jsx";
import {
  fetchRecentTransactions,
} from "../api/transactions.js";
import { useUserData } from "../context/UserProvider.js";
import WalletModal from "../components/WalletModal/WalletModal.jsx";
import { getEthValueInUSD } from "../utils/convert.js";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";


function TransactionsPage() {
  const [transactions, setTransactions] = useState([]);
  const [showWalletModal, setShowWalletModal] = useState(false); 
  const [isLoading, setIsLoading] = useState(true);
  const { userData } = useUserData();

  const address = userData.walletAddress || "";

  useEffect(() => {

    const getRecentTransactions = async () => {
      setIsLoading(true);
      if (address) {
        try {
          const data = await fetchRecentTransactions(address);

          // Convert the ETH value to USD for each transaction
          for (let event of data) {
            const payment = event.payment?.quantity / 10 ** 18;
            console.log(payment);
            if (!isNaN(payment)) {
              event.payment = payment;
              event.usdValue = await getEthValueInUSD(payment);
            }
          }

          setTransactions(data);
        } catch (error) {
          // Handle the error by showing the wallet modal
          setShowWalletModal(true);
          console.error(error);
        }
      }
      setIsLoading(false);
    };

    getRecentTransactions();
  }, [address]);

  const renderWalletModal = () => {
    if (showWalletModal) {
      return <WalletModal open={showWalletModal} />;
    }
    return null;
  };



  const formattedEthPrice = (event) => {
    if (event.payment === undefined || event.usdValue === undefined) {
      return "-";
    }

    return (
      <h3 className="nftValues">
        {String(event.payment)}{" "}
        <svg
          width="7"
          height="11"
          viewBox="0 0 7 11"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6.74697 5.60312L3.3746 7.59688L0 5.60312L3.3746 0L6.74697 5.60312ZM3.3746 8.23711L0 6.24336L3.3746 11L6.74919 6.24336L3.3746 8.23711Z"
            fill="white"
          />
        </svg>
        <span className="usdPrice">(≈ ${event.usdValue.toFixed(2)})</span>
      </h3>
    );
  };

  return (
    <div>
      {renderWalletModal()}
      <div className="pageGrid">
        <div className="transactionsTable">
          <div className="tableHeader">
            <h3>Image</h3>
            <h3>Item</h3>
            <h3>Collection</h3>
            <h3>Value</h3>
            <h3>Date</h3>
            <h3>Event</h3>
          </div>
          {transactions.map((event) => (
            <TableRow
              key={event.id}
              nftImage={event.nft?.image_url || "/images/placeholderNFT.png"}
              nftTitle={event.nft?.name || `#${event?.nft?.token_id}`}
              collectionName={event.nft?.collection}
              nftValue={formattedEthPrice(event)}
              transactionDate={new Date(
                event.event_timestamp * 1000
              ).toLocaleString("en-US", {
                dateStyle: "long",
                timeStyle: "short",
              })}
              eventType={event.event_type}
            />
          ))}
        </div>
      </div>
    </div>
  );
}

export default TransactionsPage;
