import React from 'react'
import './SlideInformation.scss'
import greyStar from '../../images/grey5Stars.png'


function SlideInformation(props) {
  return (
    <div>
      <p className='innerText'>{props.text}</p>
      <p className='name'>{props.name}</p>
    </div>
    // </div>
  )
}

export default SlideInformation