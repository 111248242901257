export const environmentUtil = () => {
    const location = window.location.host;
    
    if (location === "beardanalytics.com" || location === "www.beardanalytics.com") {
        return "production";
    }
    if (location.includes("local") || location.includes("127.0.0.1")) {
        return "development";
    }
    return "development";
};
