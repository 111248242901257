import { createContext, useContext, useEffect, useState } from "react";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { doc, getDoc, onSnapshot, setDoc } from "firebase/firestore";
import { auth, db } from "../firebaseConfig";

const UserDataContext = createContext(undefined);

export const UserDataProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [profileData, setProfileData] = useState(null);
  const [userData, setUserData] = useState(null);
  const { currentUser } = auth;

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      getUserData(user?.uid || "");
      if (user?.uid === undefined) {
        setIsLoading(false);
      }
    });
    return () => {
      unsubscribe();
    };
  }, []);

  const getUserData = async (uid, loginData) => {
    if (!uid) return;
    const docRef = doc(db, "users", uid);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      const userData = docSnap.data();
      setUserData(userData);
    } else {
      if (loginData) {
        await setDoc(docRef, loginData);
        setUserData(loginData);
      } else {
        console.error("loginData is undefined");
      }
    }
    if (isLoading) {
      setIsLoading(false);
    }
  };

  const refreshUserData = async () => {
    if (currentUser != null) {
      await getUserData(currentUser.uid);
    }
  };

  const getUserProfile = async (uid) => {
    if (!uid) return;
    const docRef = doc(db, "profiles", uid);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      const profileData = docSnap.data();
      setProfileData(profileData);
    } else {
      await setDoc(docRef, { uid });
    }
  };

  const updateUserData = async (uid, newData) => {
    if (!uid) return;

    const docRef = doc(db, "users", uid);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      await setDoc(docRef, { ...docSnap.data(), ...newData });
      setUserData({ ...docSnap.data(), ...newData });
    } else {
      await setDoc(docRef, newData);
      setUserData(newData);
    }
  };

  useEffect(() => {
    if (currentUser) {
      const unSubUser = onSnapshot(doc(db, "users", currentUser.uid), (doc) => {
        const data = doc.data();
        if (data) {
          setUserData(data);
        }
      });

      const unSubProfile = onSnapshot(
        doc(db, "profiles", currentUser.uid),
        (doc) => {
          const data = doc.data();
          if (data) {
            setProfileData(data);
          }
        }
      );

      return () => {
        unSubUser();
        unSubProfile();
      };
    }
  }, [currentUser]);

  useEffect(() => {
    if (currentUser) {
      getUserData(currentUser.uid);
      getUserProfile(currentUser.uid);
    }
  }, [currentUser]);


  return (
    <UserDataContext.Provider
      value={{
        isLoading,
        profileData,
        userData,
        getUserData,
        refreshUserData,
        getUserProfile,
        updateUserData,
      }}
    >
      {children}
    </UserDataContext.Provider>
  );
};

export const useUserData = () => useContext(UserDataContext);
