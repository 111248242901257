import { auth } from "../firebaseConfig";
import { createUserWithEmailAndPassword} from "firebase/auth";

export default async function signUp(email, password) {
  let result = null,
    error = null;

  try {
    result = await createUserWithEmailAndPassword(auth, email, password);
    const user = result.user;
  } catch (e) {
    error = e;
    const errorCode = error.code;
    const errorMessage = error.message;
    console.log("Error signing up:", errorMessage);
  }

  return { result, error };
}
