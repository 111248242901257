import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import "./WalletModal.scss";
import { Link } from "react-router-dom";


function WalletModal({ open, handleClose }) {
  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="modalContainer">
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Wallet Address Required
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Please enter a valid wallet address to receive the results of the dashboard. Go to the <Link style={{color: "#5767D9"}}to="/dashboard/settings">settings page</Link> to continue.
          </Typography>
        </Box>
      </Modal>
    </>
  );
}

export default WalletModal;
