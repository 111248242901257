import React from 'react'
import './Navbar.scss'
import logo from '../../images/logo.svg'

function Navbar() {
    return (
        <div id='navbar'>
            <div className='navbar'>
                <img src={logo} alt="logo" className='logo' />
                <div className='navlinks'>
                    <a href="#features" className='navLink'>Capabilities</a>
                    <a href="#features" className='navLink'>Process</a>
                    <a href="" className='navLink'>Testimonials</a>
                    <a href="mailto:admin@blackbeard.xyz" className='navLink'>Contact</a>
                </div>
                <div className='btnGroup'>
                    {/* <a href="https://opensea.io/collection/beardanalytics" className='btnOutline'>Opensea</a> */}
                    <a href="/dashboard" className='btnSolid'>Join Now</a>
                </div>
            </div>
        </div>
    )
}

export default Navbar
