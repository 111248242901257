import React, { useState } from "react";
import "./SoldNFT.scss";
// import nft from '../../images/nft.png'

function SoldNFT({
  buyPrice,
  sellPrice,
  profitPrice,
  collectionName,
  nftId,
  nftImage,
  nftstats,
}) {
  const Media = ({ src }) => {
    const [isVideo, setIsVideo] = useState(false);

    const handleLoadError = () => {
      setIsVideo(true);
    };

    const mediaContent = isVideo ? (
      <img
        src={nftImage || "/images/placeholderNFT.png"}
        alt="nft"
        className="nftImage"
        width={50}
        height={50}
        onError={(e) => {
          e.target.onerror = null;
          e.target.src = "/images/placeholderNFT.png";
        }}
      />
    ) : (
      <video
        muted
        disablePictureInPicture
        controls={false}
        autoPlay={true}
        name="media"
        className="nftImage"
        onError={handleLoadError}
        loop={true}
      >
        <source src={nftImage} type="video/mp4" />
      </video>
    );

    return mediaContent;
  };

  return (
    <div className="nftContainer">
      <div className="nftOverlay">
        <Media src={nftImage} />
        {nftstats === "true" ? (
          <div className="nftStats">
            <div>
              <h4>Buy</h4>
              <h3>
                {buyPrice}{" "}
                <svg
                  className="ethLogo"
                  width="9"
                  height="17"
                  viewBox="0 0 7 11"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6.74697 5.60312L3.3746 7.59688L0 5.60312L3.3746 0L6.74697 5.60312ZM3.3746 8.23711L0 6.24336L3.3746 11L6.74919 6.24336L3.3746 8.23711Z"
                    fill="white"
                  />
                </svg>
              </h3>
            </div>
            <div>
              <h4>Sell</h4>
              <h3>
                {sellPrice}{" "}
                <svg
                  className="ethLogo"
                  width="9"
                  height="17"
                  viewBox="0 0 7 11"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6.74697 5.60312L3.3746 7.59688L0 5.60312L3.3746 0L6.74697 5.60312ZM3.3746 8.23711L0 6.24336L3.3746 11L6.74919 6.24336L3.3746 8.23711Z"
                    fill="white"
                  />
                </svg>
              </h3>
            </div>
            <div>
              <h4>Profit</h4>
              <h3>
                {profitPrice}
                <svg
                  className="ethLogo"
                  width="9"
                  height="17"
                  viewBox="0 0 7 11"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6.74697 5.60312L3.3746 7.59688L0 5.60312L3.3746 0L6.74697 5.60312ZM3.3746 8.23711L0 6.24336L3.3746 11L6.74919 6.24336L3.3746 8.23711Z"
                    fill="white"
                  />
                </svg>
              </h3>
            </div>
          </div>
        ) : null}
      </div>
      <div className="nftInfo">
        <h3>{collectionName}</h3>
        <h4>{nftId}</h4>
      </div>
    </div>
  );
}

export default SoldNFT;
