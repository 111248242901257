import { sendPasswordResetEmail } from "firebase/auth";
import { auth } from "../firebaseConfig";

export default async function resetPassword(email) {
let result = null,
    error = null;

  try {
    result = await sendPasswordResetEmail(auth, email)
  } catch (e) {
    error = e;
    console.log("Error resetting password:", error.message);
  }

  return { result, error };
}
