import React from 'react'
import './Landing.scss'
import landingui from '../../images/landingui.png'

function Landing() {
    return (
        <div id='landing'>
            <div className='landingContainer'>
                <h1>Track, Analyze, and Optimize Your NFT Investments.</h1>
                <p>Streamline portfolio management, track performance, and optimize for success.</p>
                <div className='landingCardGroup'>
                    <div className='landingCard'>
                        <h2>Collecting</h2>
                        <p>Optimize the collection process of tracking and analyzing NFTs that you acquire.</p>
                    </div>
                    <div className='landingCard'>
                        <h2>Resell</h2>
                        <p>Find the right platforms, NFTs, and other sources to help your NFT reselling journey.</p>
                    </div>
                </div>
                <div className='btnGroup landBtnGroup'>
                    <a href="https://discord.gg/8G4YNGhvbT" className='btnSolid btnPurple'>Get Started</a>
                    {/* <a href="https://discord.gg/8G4YNGhvbT" className='btnOutline'>Learn More</a> */}
                </div>
            </div>

            <img src={landingui} alt="ui" className='landingui' />
        </div>
    )
}

export default Landing
