import React from "react";
import "./Navbar.scss";
import {
  Iconly,
  Category,
  Activity,
  Paper,
  Folder,
  Setting,
} from "react-iconly";
import packageJson from "../../../package.json";
import { Link, useLocation } from "react-router-dom";

function Navbar() {
  const location = useLocation();
  const { pathname } = location;

  return (
    <div className="navbar">
      <div className="iconlyGrid">
        <Link to="/dashboard">
          {pathname === "/dashboard" ? (
            <Iconly
              name="Category"
              set="bulk"
              primaryColor="#5767D9"
              className="iconly"
            />
          ) : (
            <Iconly
              name="Category"
              set="bulk"
              primaryColor="#677177"
              className="iconly"
            />
          )}
        </Link>

        <Link to="/dashboard/analytics">
          {pathname === "/dashboard/analytics" ? (
            <Iconly
              name="Activity"
              set="bulk"
              primaryColor="#5767D9"
              className="iconly"
            />
          ) : (
            <Iconly
              name="Activity"
              set="bulk"
              primaryColor="#677177"
              className="iconly"
            />
          )}
        </Link>

        <Link to="/dashboard/transactions">
          {pathname === "/dashboard/transactions" ? (
            <Iconly
              name="Paper"
              set="bulk"
              primaryColor="#5767D9"
              className="iconly"
            />
          ) : (
            <Iconly
              name="Paper"
              set="bulk"
              primaryColor="#677177"
              className="iconly"
            />
          )}
        </Link>

        <Link
          to="/dashboard/collection"
          className={
            pathname === "/dashboard/collection" ? "navLink active" : "navLink"
          }
        >
          {pathname === "/dashboard/collection" ? (
            <Iconly
              name="Folder"
              set="bulk"
              primaryColor="#5767D9"
              className="iconly"
            />
          ) : (
            <Iconly
              name="Folder"
              set="bulk"
              primaryColor="#677177"
              className="iconly"
            />
          )}
        </Link>

        <Link
          to="/dashboard/settings"
          className={
            pathname === "/dashboard/settings" ? "navLink active" : "navLink"
          }
        >
          {pathname === "/dashboard/settings" ? (
            <Iconly
              name="Setting"
              set="bulk"
              primaryColor="#5767D9"
              className="iconly"
            />
          ) : (
            <Iconly
              name="Setting"
              set="bulk"
              primaryColor="#677177"
              className="iconly"
            />
          )}
        </Link>
      </div>
      <p className="versionNumber">{packageJson.version}</p>
    </div>
  );
}

export default Navbar;
