import React, { useEffect, useState } from "react";
import SoldNFT from "../components/SoldNFT/SoldNFT.jsx";
import { getNfts } from "../api/collection.js";
import { useUserData } from "../context/UserProvider.js";
import WalletModal from "../components/WalletModal/WalletModal.jsx";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

function CollectionPage() {
  const [nfts, setNfts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [showWalletModal, setShowWalletModal] = useState(false);
  const { userData } = useUserData();

  const address = userData.walletAddress || "";
  const apiKey = `${process.env.REACT_APP_ALCHEMY_API_KEY}`;

  useEffect(() => {


    async function fetchNfts() {
      setIsLoading(true);

      try {
        const nftsData = await getNfts(address, apiKey);
        setNfts(nftsData);
      } catch (error) {
        setShowWalletModal(true);
        console.log(error);
      }
      setIsLoading(false);
    }
    fetchNfts();
  }, [address, apiKey, userData.walletAddress]);

  const renderWalletModal = () => {
    if (showWalletModal) {
      return <WalletModal open={showWalletModal} />;
    }
    return null;
  };

  return (
    <div className="pastSales collectionWidth">
            {renderWalletModal()}
      <div className="salesGrid">
        {nfts.map((nft, index) => (
          <SoldNFT
            key={index}
            buyPrice="8.4"
            sellPrice="15.6"
            profitPrice="7.2"
            collectionName={
              nft.contractMetadata.openSea.collectionName
                ? nft.contractMetadata.openSea.collectionName
                : "No Title"
            }
            nftId={nft.title}
            nftImage={
              nft.media[0].gateway ||
              nft.media[0].gateway ||
              "/images/placeholderNFT.png"
            }
          />
        ))}
      </div>
    </div>
  );
}

export default CollectionPage;
