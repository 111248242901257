import React, { useEffect, useState } from "react";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { CustomTooltip } from "./CustomTooltip.jsx";
import { fetchRecentActivity } from "../../api/analytics.js";
import { useUserData } from "../../context/UserProvider.js";

function Graph() {
  const [activity, setActivity] = useState([]);
  const { userData } = useUserData();

  const address = userData.walletAddress || "";

  useEffect(() => {
    async function fetchActivity() {
      const activity = await fetchRecentActivity(address);
      setActivity(activity);
    }

    fetchActivity();
  }, []);

  const data = activity.reduce((acc, curr) => {
    const date = new Date(curr.event_timestamp * 1000);
    const month = date.toLocaleString("default", { month: "short" });
    const year = date.getFullYear();
    const name = `${month} ${year}`;
    const profitPrice = parseFloat(curr.profitPrice);

    const index = acc.findIndex((item) => item.name === name);
    if (index !== -1) {
      acc[index].value += profitPrice;
    } else {
      acc.unshift({ name, value: profitPrice });
    }

    return acc;
  }, []);

  // console.log(data)

  return (
    <>
      <h2 className="graphTitle">Profit Chart</h2>
      <ResponsiveContainer height={"80%"}>
        <AreaChart data={data}>
          <defs>
            <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#5767D9" stopOpacity={0.7} />
              <stop offset="95%" stopColor="#5767D9" stopOpacity={0} />
            </linearGradient>
            <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#5767D9" stopOpacity={0.7} />
              <stop offset="95%" stopColor="#5767D9" stopOpacity={0} />
            </linearGradient>
          </defs>
          <Tooltip
            content={<CustomTooltip />}
            cursor={{ fill: "transparent" }}
            wrapperStyle={{ outline: "none" }}
          />
          {/* <XAxis dataKey="name" />
                    <YAxis /> */}
          <Area
            type="monotone"
            dataKey="value"
            stroke="#5767D9"
            strokeWidth={2}
            fillOpacity={0.6}
            fill="url(#colorUv)"
          />
        </AreaChart>
      </ResponsiveContainer>
    </>
  );
}

export default Graph;
