import React from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import App from "./App";
import { UserDataProvider } from "./context/UserProvider.js";
import { BrowserRouter } from "react-router-dom";

(async () => {
  ReactDOM.createRoot(document.getElementById("root")).render(
    <React.StrictMode>
      <UserDataProvider>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </UserDataProvider>
    </React.StrictMode>
  );
})();
