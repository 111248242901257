import React, { useEffect, useState } from "react";
import RecentTransactions from "../components/RecentTransactions/RecentTransactions.jsx";
import { fetchRecentTransactions } from "../api/transactions.js";
import { Link } from "react-router-dom";
import { getNfts } from "../api/collection.js";
import SoldNFT from "../components/SoldNFT/SoldNFT.jsx";
import Graph from "../components/Graph/Graph.jsx";
import { useUserData } from "../context/UserProvider.js";
import WalletModal from "../components/WalletModal/WalletModal.jsx";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { weiToEth } from "../utils/convert.js";

function Dashboard() {
  const { userData } = useUserData();
  const [showWalletModal, setShowWalletModal] = useState(false);
  const address = userData.walletAddress || "";
  const [transactions, setTransactions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [nfts, setNfts] = useState([]);
  const [error, setError] = useState(null);
  const apiKey = `${process.env.REACT_APP_ALCHEMY_API_KEY}`;

  useEffect(() => {
    const getRecentTransactions = async () => {
      setIsLoading(true);

      const data = await fetchRecentTransactions(address);
      setTransactions(data);
      setIsLoading(false);
    };

    getRecentTransactions();
  }, [address]);

  useEffect(() => {
    async function fetchNfts() {
      setIsLoading(true);

      try {
        const nftsData = await getNfts(address, apiKey);
        setNfts(nftsData);
      } catch (error) {
        setError(error);
        setShowWalletModal(true);
      }
      setIsLoading(false);
    }
    fetchNfts();
  }, [address, apiKey]);

  const formattedEthPrice = (event) => {
    console.log(event.payment);
    if (event?.payment === undefined) {
      return "-";
    } else {
      return (
        <h3 className="nftValues">{weiToEth(event.payment.quantity)} ETH</h3>
      );
    }
  };

  const renderWalletModal = () => {
    if (showWalletModal) {
      return <WalletModal open={showWalletModal} />;
    }
    return null;
  };

  return (
    <div className="dashboardContainer">
      {renderWalletModal()}

      <div className="rowOne">
        <div className="graphContainer">
          <Graph />
        </div>
        <div className="transactionsContainer">
          <div className="containerHeader">
            <h2>Recent Transactions</h2>
            <Link to="/dashboard/transactions" className="viewAll">
              <h3 className="viewAll">View All</h3>
            </Link>
          </div>
          <div className="overflowControl">
            {isLoading
              ? Array.from({ length: 4 }).map((_, index) => (
                  <div key={index}>
                    <Skeleton
                      height={45}
                      width="100%"
                      baseColor="#1E1F23"
                      highlightColor="#24262B"
                    />
                  </div>
                ))
              : transactions &&
                transactions.length > 0 &&
                transactions.slice(0, 4).map((event) => (
                  <div key={event.id}>
                    <RecentTransactions
                      nftImage={event.nft?.image_url}
                      nftTitle={
                        event.asset?.name ||
                        event.asset?.name ||
                        event.asset?.token_id ||
                        event.nft?.name
                      }
                      nftValue={formattedEthPrice(event)}
                      eventType={event?.event_type}
                    />
                  </div>
                ))}
          </div>
        </div>
      </div>
      <div className="collectionContainer">
        <div className="containerHeader collectionHeader">
          <h2>My Collection</h2>
          <Link to="/dashboard/collection" className="viewAll">
            <h3 className="viewAll">View All</h3>
          </Link>
        </div>
        <div className="collectionSlider">
          {isLoading
            ? Array.from({ length: 4 }).map((_, index) => (
                <div key={index}>
                  <Skeleton
                    width="100%"
                    height="280px"
                    baseColor="#1E1F23"
                    highlightColor="#24262B"
                  />
                </div>
              ))
            : nfts
                .slice(0, 4)
                .map((nft, index) => (
                  <SoldNFT
                    key={index}
                    collectionName={
                      nft.contractMetadata.openSea.collectionName
                        ? nft.contractMetadata.openSea.collectionName
                        : "No Title"
                    }
                    nftId={nft.title}
                    nftImage={
                      nft.media[0].gateway
                        ? nft.media[0].gateway
                        : "/images/placeholderNFT.png"
                    }
                  />
                ))}
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
