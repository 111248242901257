// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getAnalytics } from "firebase/analytics";

import { environmentUtil } from "./utils/environment";
const environment = environmentUtil();

let firebaseConfig;

if (environment === "production") {
  firebaseConfig = {
    apiKey: "AIzaSyDWpbn9rJSzQIwNI_RSWRFQOB7Uvv7yT1k",
    authDomain: "blackbeard-production-fa8e4.firebaseapp.com",
    projectId: "blackbeard-production-fa8e4",
    storageBucket: "blackbeard-production-fa8e4.appspot.com",
    messagingSenderId: "737212472996",
    appId: "1:737212472996:web:8cab446e3b866b87d41031",
    measurementId: "G-82T7YJYQM5",
  };
} else {
  firebaseConfig = {
    apiKey: "AIzaSyAf0Vcz-TkbpEeZ2LheOev8SBl7uPadxoE",
    authDomain: "blackbeard-dd9fb.firebaseapp.com",
    projectId: "blackbeard-dd9fb",
    storageBucket: "blackbeard-dd9fb.appspot.com",
    messagingSenderId: "142680908756",
    appId: "1:142680908756:web:25d38f503c5db4eb75ac41",
    measurementId: "G-MFYSCF43PN",
  };
}

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);
const analytics = getAnalytics(app);
// export const storage = getStorage(app);
// export const functions = getFunctions(app, "us-central1");
// export const firestore = getFirestore(app);
// export const database = getDatabase(app);

// if (environment === "localhost") {
//   connectAuthEmulator(auth, `http://${location.hostname}:9099`, {
//     disableWarnings: true,
//   });
//   connectFunctionsEmulator(functions, location.hostname, 5001);
//   connectFirestoreEmulator(firestore, location.hostname, 8080);
//   connectDatabaseEmulator(database, location.hostname, 9000);
//   connectStorageEmulator(storage, location.hostname, 9199);
// }
