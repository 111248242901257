import axios from 'axios';
const baseURL = 'https://eth-mainnet.g.alchemy.com/v2/';

export const getNfts = async (address, apiKey) => {
    const url = `${baseURL}${apiKey}/getNFTs/?owner=${address}`;

    try {
        const response = await axios.get(url);
        return response.data.ownedNfts;
    } catch (error) {
        throw new Error(error.message);
    }
};
