import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SoldNFT from "../components/SoldNFT/SoldNFT";
import { fetchRecentActivity } from "../api/analytics.js";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { useUserData } from "../context/UserProvider.js";
import WalletModal from "../components/WalletModal/WalletModal.jsx";
import { weiToEth } from "../utils/convert.js";

function AnalyticsPage() {
  const [activity, setActivity] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [showWalletModal, setShowWalletModal] = useState(false);
  const { userData } = useUserData();

  const address = userData.walletAddress || "";

  useEffect(() => {
    const fetchActivity = async () => {
      if (!address) {
        setIsLoading(false);
        setShowWalletModal(true);
        return;
      }
      try {
        const data = await fetchRecentActivity(address);
        if (data?.length === 0) {
          toast.error("No data found for the address");
        } else {
          setActivity(data);
        }
      } catch (error) {
        toast.error("Error fetching recent activity");
        setShowWalletModal(true);
      } finally {
        setIsLoading(false);
      }
    };

    fetchActivity();
  }, [address]);

  return (
    <div>
      {showWalletModal && (
        <WalletModal
          open={showWalletModal}
          onClose={() => setShowWalletModal(false)}
        />
      )}

      <ToastContainer
        position="bottom-center"
        theme="colored"
        draggable={false}
        pauseOnHover={false}
      />
      <div className="pageScroll">
        <div className="analyticGrid">
          {isLoading ? (
            Array.from({ length: 4 }).map((_, index) => (
              <div key={index} className="statContainer">
                <Skeleton
                  height={25}
                  width={100}
                  baseColor="#1E1F23"
                  highlightColor="#24262B"
                />
              </div>
            ))
          ) : (
            <>
              <div className="statContainer">
                <h4>ROI (Realized)</h4>
                <h3>Coming Soon</h3>
              </div>
              <div className="statContainer">
                <h4>Revenue</h4>
                <h3>Coming Soon</h3>
              </div>
              <div className="statContainer">
                <h4>Total Spend</h4>
                <h3>Coming Soon</h3>
              </div>
              <div className="statContainer">
                <h4>Inventory Value</h4>
                <h3>Coming Soon</h3>
              </div>
            </>
          )}
        </div>

        <div className="pastSales">
          <h2>Past Sales</h2>
          <div className="salesGrid">
            {isLoading
              ? Array.from({ length: 4 }).map((_, index) => (
                  <div key={index} className="nftContainer">
                    <Skeleton
                      width="100%"
                      height="280px"
                      baseColor="#1E1F23"
                      highlightColor="#24262B"
                    />
                  </div>
                ))
              : activity.map(
                  (event) => (
                    console.log(event),
                    (
                      <SoldNFT
                        key={event.id}
                        nftstats="true"
                        collectionName={event.nft.collection}
                        nftId={event.nft.name || "#" + event.nft.identifier}
                        nftImage={
                          event.nft?.image_url || "/images/placeholderNFT.png"
                        }
                        buyPrice={weiToEth(event.buyPrice)}
                        sellPrice={weiToEth(event.payment.quantity)}
                        profitPrice={weiToEth(event.profitPrice)}
                      />
                    )
                  )
                )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default AnalyticsPage;
