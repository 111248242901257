import React, { useState, useEffect } from "react";
import "./Header.scss";
import { Link, useLocation } from "react-router-dom";
import { useUserData } from "../../context/UserProvider.js";

function Header() {
  const location = useLocation();
  const { pathname } = location;
  const [currentPage, setCurrentPage] = useState("");
  const { userData } = useUserData();

  const address = userData.walletAddress || "";

  useEffect(() => {
    if (pathname === "/dashboard") {
      setCurrentPage("Dashboard");
    } else if (pathname === "/dashboard/analytics") {
      setCurrentPage("Analytics");
    } else if (pathname === "/dashboard/transactions") {
      setCurrentPage("Transactions");
    } else if (pathname === "/dashboard/collection") {
      setCurrentPage("Collection");
    } else if (pathname === "/dashboard/settings") {
      setCurrentPage("Settings");
    } else {
      setCurrentPage("Not Found");
    }
  }, [pathname]);

  return (
    <div className="header">
      <h1>{currentPage}</h1>
      <div className="profileInfo">
        <div className="ethWallet">
          <div className="overflowElipsis">
            <p>{address || "No Address"}</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header;
