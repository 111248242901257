import React from "react";
import "./Testimonials.scss";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, A11y } from "swiper/modules";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css";
import SlideInformation from "../SlideInformation/SlideInformation";

import greyStar from "../../images/grey5Stars.png";

function Testimonials() {
  return (
    <div id="testimonials">
      <div className="testimonialsHeader">
        <h1>Unparalleled NFT Portfolio Management: Real Results, Real Trust</h1>
        <h2 className="subTitle">
          Explore Authentic User Stories and Experience the Confidence That
          Comes with the Best in NFT Management.
        </h2>
      </div>

      <div className="blob"></div>

      <div className="testimonialView">
        <Swiper
          modules={[Autoplay, Pagination, A11y]}
          spaceBetween={30}
          slidesPerView={2}
          loop={true}
          centeredSlides={true}
          clickable={true}
          autoplay={{
            delay: 3000,
            disableOnInteraction: false,
          }}

          breakpoints={{
            200: {
              slidesPerView: 1,
              spaceBetween: 20,
            },
            640: {
              slidesPerView: 2,
              spaceBetween: 20,
            },
            768: {
              slidesPerView: 2,
              spaceBetween: 40,
            },
            1024: {
              slidesPerView: 2,
              spaceBetween: 30,
            },
          }}

          pagination={{ clickable: true }}
          onSwiper={(swiper) => console.log(swiper)}
          onSlideChange={() => console.log("slide change")}
        >
          <SwiperSlide>
            <div className="outterClass">
              <span alt="stars" className="stars" />
              <h1 className="title">Would 100% Recommend</h1>
              <SlideInformation
                // title="Would 100% Recommend"
                text="Blackbeard is my digital compass in the vast world of NFTs. It doesn't just manage my collection; with profit and loss insights, it's like having a curator for my NFT journey. "
                name="Sophie Turner"
              />
            </div>
          </SwiperSlide>

          <SwiperSlide>
            <div className="outterClass">
              <span alt="stars" className="stars" />
              <h1 className="title">A Must-Have for NFT Investors!</h1>
              <SlideInformation
                text="Blackbeard is my secret weapon in the NFT investment game. It transforms numbers into insights, helping me navigate the market. "
                name="Ethan Clark"
              />
            </div>
          </SwiperSlide>

          <SwiperSlide>
            <div className="outterClass">
              <span alt="stars" className="stars" />
              <h1 className="title">Can't Live Without It!</h1>
              <SlideInformation
                text="Blackbeard amplifies enables me to enhance my analytical mindset through my NFTs."
                name="Isabella Martinez"
              />
            </div>
          </SwiperSlide>

          <SwiperSlide>
            <div className="outterClass">
              <span alt="stars" className="stars" />
              <h1 className="title">Elevates NFT Experience</h1>
              <SlideInformation
                text="Blackbeard is not just a platform; it's a partner that brings my NFT portfolio to life. From analyzing sales to crafting stunning collection views, it's a game-changer."
                name="Lucas Reynolds"
              />
            </div>
          </SwiperSlide>

          <SwiperSlide>
            <div className="outterClass">
              <span alt="stars" className="stars" />
              <h1 className="title">Essential For NFT Explorers!</h1>
              <SlideInformation
                text="Blackbeard turns my NFT exploration into an adventure. Beyond tracking profits, it paints a picture of my journey in the digital realm."
                name="Zara Taylor"
              />
            </div>
          </SwiperSlide>

          <SwiperSlide>
            <div className="outterClass">
              <span alt="stars" className="stars" />
              <h1 className="title">Indispensable For NFT Mastery!</h1>
              <SlideInformation
                text="Blackbeard is the strategist's tool in the blockchain arena. It elevates my NFT portfolio management to an art form."
                name="Mason Carter"
              />
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
    </div>
  );
}

export default Testimonials;
