import React, { useState } from "react";

function TableRow({
  nftImage,
  collectionName,
  nftTitle,
  nftValue,
  transactionDate,
  eventType,
  usdValue,
}) {
  const Media = ({ src }) => {
    const [isVideo, setIsVideo] = useState(false);

    const handleLoadError = () => {
      setIsVideo(true);
    };

    const mediaContent = isVideo ? (
      <img
        src={nftImage || "/images/placeholderNFT.png"}
        alt="nft"
        className="nftImage"
        width={50}
        height={50}
        onError={(e) => {
          e.target.onerror = null;
          e.target.src = "/images/placeholderNFT.png";
        }}
      />
    ) : (
      <video
        disablePictureInPicture="true"
        muted
        controls={false}
        autoPlay={true}
        name="media"
        className="nftImage"
        onError={handleLoadError}
        loop={true}
      >
        <source src={nftImage} type="video/mp4" />
      </video>
    );

    return mediaContent;
  };

  return (
    <div className="tableRow">
      <Media src={nftImage} />
      <h3>{nftTitle}</h3>
      <h3>{collectionName}</h3>
      <h3>{nftValue}</h3>
      <h3>{transactionDate}</h3>
      <h3>{eventType}</h3>
    </div>
  );
}

export default TableRow;
