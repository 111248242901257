import React from 'react'
import './Footer.scss'
import footerlogo from '../../images/logo.svg'

function Footer() {
    return (
        <div id='footer'>

            <div>
                <img src={footerlogo} alt="Resellology" className='logo' />
                <p className='copyright'>© 2023 BlackBeard - All Rights Reserved.</p>
            </div>

            <div className='footerChild'>
                <p className='bold'>Website</p>
                <a href="#navbar" className='footerChild'>Home</a>
                <a href="#features" className='footerChild'>Features</a>
                <a href="mailto:admin@blackbeard.xyz" className='footerChild'>Contact</a>
            </div>

            <div className='footerChild'>
                <p className='bold'>Socials</p>
                <a href="https://twitter.com/BeardAnalytics" className='footerChild'>Twitter</a>
                <a href="https://discord.gg/8G4YNGhvbT" className='footerChild'>Discord</a>
            </div>

            <div className='btnGroup'>
                {/* <a href="https://opensea.io/collection/beardanalytics" className='btnOutline'>Opensea</a> */}
                <a href="/dashboard" className='btnSolid'>Join Now</a>
            </div>

            {/* <div className='footerChild'>
                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ea voluptatem ipsa pariatur sunt hic perferendis inventore eum aperiam tempora libero alias a dicta corporis, natus voluptatibus nam quis veniam quaerat sint, cum nulla id totam eveniet iste! Cum dolorum cumque qui dolor ea sapiente repellendus, provident, animi suscipit, voluptates nostrum.</p>
            </div> */}

            {/* <div className='footerChild'>
                <p className='bold'>Legal</p>
                <p>Privacy Policy</p>
                <p>Terms of Service</p>
                <p>Cookie Policy</p>
                <p>Disclaimer</p>
            </div> */}
        </div>
    )
}

export default Footer
