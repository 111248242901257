export const isMobileDevice = function () {
  return (
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    ) || window.matchMedia("(max-width: 1150px)").matches
  );
};

export const isStandaloneMode = function () {
  return window.matchMedia("(display-mode: standalone)").matches;
};
