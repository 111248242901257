import React from "react";
// import nftImage from '../../images/nft.png'


function RecentTransactions({ nftImage, nftTitle, nftValue, eventType }) {
  
  return (
    <div className="recentTransaction">
      <img
        src={nftImage || "/images/placeholderNFT.png"}
        alt="nft"
        className="nftImage"
        onError={(e) => {
          e.target.onerror = null; 
          e.target.src = "/images/placeholderNFT.png";
        }}
      />
      <div>
        <h3 style={{ marginBottom: 4 }}>{nftTitle || "NFT doesn't exist"}</h3>
        <h3 style={{ opacity: 0.7 }}>{nftValue}</h3>
      </div>
      <h3 className="eventText">{eventType}</h3>
    </div>
  );
}

export default RecentTransactions;
