import React, { useState } from "react";
import resetPassword from "../auth/resetPassword.js";
import { Link } from "react-router-dom";

function ResetPassword() {
  const [email, setEmail] = useState("");
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  const handleForm = async (event) => {
    event.preventDefault();

    const { result, error } = await resetPassword(email);
    setSuccess(true);

    if (error) {
      console.log(error);
      setError(error.message);
      return;
    }
  };

  return (
    <div className="flex min-h-full flex-1 flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <img
          src="/images/logo.png"
          alt="BlackBeard"
          className="mx-auto h-10 w-auto"
        />
        <h2 className="mt-6 text-center text-2xl font-bold leading-9 tracking-tight text-white">
          Forgot Password
        </h2>
      </div>

      <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-[480px]">
        <div className="bg-main px-6 py-12 shadow sm:rounded-lg sm:px-12">
          <form
            onSubmit={handleForm}
            className="space-y-6"
            action="#"
            method="POST"
          >
            {success && (
              <p
                className="text-green-500
"
              >
                If your email is in the database, check your email for a
                password reset link. If you don't see it, check your spam
                folder.{" "}
                <Link
                  to="/dashboard/sign-in"
                  className=" text-second hover:text-indigo-500"
                >
                  Back to Login
                </Link>
              </p>
            )}
            <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium leading-6 text-gray-400"
              >
                Email address
              </label>
              <div className="mt-2">
                <input
                  onChange={(e) => setEmail(e.target.value)}
                  required
                  type="email"
                  name="email"
                  id="email"
                  placeholder="example@mail.com"
                  autoComplete="email"
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  
                />
              </div>
            </div>

            <div>
              <button
                type="submit"
                className="flex w-full justify-center rounded-md bg-second px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                Reset Password
              </button>
            </div>
          </form>
        </div>
      </div>
      <p className="mt-10 text-center text-sm text-gray-500">
        Didn't forget password?{" "}
        <Link
          to="/dashboard/sign-in"
          className="font-semibold leading-6 text-second hover:text-indigo-500"
        >
          Back to Login
        </Link>
      </p>
    </div>
  );
}

export default ResetPassword;
