import axios from "axios";

export const fetchRecentTransactions = async (address) => {
  const url = `https://api.opensea.io/api/v2/events/accounts/${address}&only_opensea=true&limit=300`;
  const options = {
    method: "GET",
    headers: { "X-API-KEY": `${process.env.REACT_APP_OPENSEA_API_KEY}` },
  };

  try {
    const response = await axios.get(url, options);
    const data = response?.data?.asset_events;
    const transactions = data.map((event) => {
      const formattedEthPrice = (event.total_price / 10 ** 18).toFixed(2);
      const transactionType = getTransactionType(event, address);

      return {
        ...event,
        formattedEthPrice,
        transactionType,
      };
    });

    return transactions;
  } catch (error) {
    console.log(error.message);
  }
};

const getTransactionType = (event, address) => {
  const { winner_account, to_account } = event;

  if (
    winner_account &&
    winner_account.address.toLowerCase() === address.toLowerCase()
  ) {
    return "Purchase";
  } else if (
    to_account &&
    to_account.address.toLowerCase() === address.toLowerCase()
  ) {
    return "Transfer";
  } else {
    return "Sale";
  }
};

