import './Graph.scss'
import {weiToEth } from '../../utils/convert.js'

export const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
        const data = payload[0].payload;
        const ethValue = weiToEth(data.value);
        // console.log(data)

        return (
            <div className="custom-tooltip">
                <p className="label1">{`${ethValue} ETH`}</p>
                <p className="label2">{`${data.name}`}</p>
                {/* <div>
                    {payload.map((pld) => (
                        <div style={{ display: "inline-block", padding: 10 }}>
                            <div style={{ color: pld.fill }}>{pld.value}</div>
                            <div>{pld.dataKey}</div>
                        </div>
                    ))}
                </div> */}
            </div>
        );
    }

    return null;
};
