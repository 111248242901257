import Navbar from "./Navbar/Navbar.jsx";
import Header from "./Header/Header.jsx";
import { Outlet } from "react-router-dom";
import { isMobileDevice } from "../utils/mobile.js";

const isMobile = isMobileDevice();

function isStandaloneMode() {
  return window.matchMedia("(display-mode: standalone)").matches;
}
const inStandaloneMode = isStandaloneMode();

const Layout = () => {
  if (isMobile || inStandaloneMode) {
    return (
      <div className="loader-container">
        <img className="image-404" src="/images/logo.png" />
        <p>
          Please use the browser on a desktop with a minimum width of 1150px to have the best experience with
          the app!
        </p>
      </div>
    );
  }

  return (
    <div className="container">
      <div className="logo logoBorder">
        <img
          src="/images/logo.png"
          width={40}
          height={40}
          alt="BlackBeard"
          className="logo"
        />
      </div>
      <div className="navbar">
        <Navbar />
      </div>
      <div className="header">
        <Header />
      </div>

      <div className="main">
        <Outlet />
      </div>
    </div>
  );
};

export default Layout;
