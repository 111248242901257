import { signOut } from "firebase/auth";
import { auth } from "../firebaseConfig";

export default async function signOutUser() {
  signOut(auth)
    .then(() => {
      console.log("Sign-out successful");
    })
    .catch((error) => {
      console.log("An error happened", error);
    });
}
