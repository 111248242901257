import React from "react";
import Navbar from "../components/WebNavbar/Navbar";
import Landing from "../components/Landing/Landing";
import Features from "../components/Features/Features";
import Testimonials from "../components/Testimonials/Testimonials";
import Footer from "../components/Footer/Footer";

function MainSite() {
    return (
        <div className="mainWebBody">
            <Navbar />
            <Landing />
            <Features />
            <Testimonials />
            <Footer />
        </div>
    )
}

export default MainSite