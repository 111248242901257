import axios from "axios";

let cachedRate = null;
let lastFetchTime = null;

export const getEthValueInUSD = async (ethAmount) => {
  try {
    let ethToUsdRate;

    const currentTime = new Date();
    const threeMinutes = 3 * 60 * 1000; // in milliseconds

    if (lastFetchTime && currentTime - lastFetchTime < threeMinutes) {
      ethToUsdRate = cachedRate;
    } else {
      const response = await axios.get(
        "https://api.coinbase.com/v2/exchange-rates?currency=ETH"
      );
      ethToUsdRate = response.data.data.rates.USD;
      cachedRate = ethToUsdRate;
      lastFetchTime = currentTime;
    }

    const ethValueInUSD = (ethAmount * ethToUsdRate);

    return ethValueInUSD;
  } catch (error) {
    console.error("Error fetching exchange rate:", error);
    return null;
  }
};

export const weiToEth = (wei) => {
  return wei / (10 ** 18);
};
