import React from 'react';
import './Features.scss';
import featureImage from '../../images/featureImage.png'
// import { Wallet, Chart, PaperDownload, Document } from 'react-iconly';

function Features() {
  return (
    <div id="features">
      <div className="featuresHeader">
        <h1>Empower Your NFT Investments with Powerful Features</h1>
        <h2 className='subTitle'>Take full control of your portfolio with Blackbeard's advanced analytics and tracking capabilities.</h2>
      </div>

      <div className="featuresGrid">
        <div>
          <img src={featureImage} alt="Feature Image" className='featureImage' />
        </div>
        <div className='featuredCardGroup'>
          <div className="featuresCard">
            {/* <div className="featureIcon">
            <Chart set="bold" primaryColor="white" />
          </div> */}
            <h2>Past Sales Analysis</h2>
            <p>Dive deep into your sales history to gain valuable insights. Identify trends, understand market dynamics, and leverage historical data to refine your investment strategy.</p>
          </div>
          <div className="featuresCard">
            {/* <div className="featureIcon">
            <Wallet set="bold" primaryColor="white" />
          </div> */}
            <h2>Real Time Profit/Loss Tracking</h2>
            <p>Stay informed about the performance of your NFT investments with up-to-the-minute profit and loss calculations. Make data-driven decisions to maximize your returns.</p>
          </div>
          <div className="featuresCard">
            {/* <div className="featureIcon">
            <Document set="bold" primaryColor="white" />
          </div> */}
            <h2>Current Collection</h2>
            <p>Easily track the combined value of your NFT collection in one centralized place. We provide a price estimate for each of your assets. Monitor their growth and gain a comprehensive overview of your asset’s worth.</p>
          </div>
          <div className="featuresCard">
            {/* <div className="featureIcon">
            <PaperDownload set="bold" primaryColor="white" />
          </div> */}
            <h2>Easy Tax Export</h2>
            <p>Simplify tax reporting with Blackbeard's seamless export functionality. Effortlessly generate comprehensive data reports to streamline the tax filing process and ensure compliance.</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Features;
