// Layout.js
import React from 'react'
import Layout from "./components/Layout.jsx";
import { Routes, Route, Navigate } from "react-router-dom";
import { useUserData } from "./context/UserProvider.js";
import Home from "./pages/Home.jsx";
import Analytics from "./pages/Analytics.jsx";
import Transactions from "./pages/Transactions.jsx";
import Collection from "./pages/Collections.jsx";
import Settings from "./pages/Settings.jsx";
import NotFound from "./pages/NotFound.jsx";
import SignUp from "./pages/SignUp.jsx";
import SignIn from "./pages/SignIn.jsx";
import ResetPassword from "./pages/ResetPassword.jsx";
import MainSite from "./pages/MainSite.jsx"
import { environmentUtil } from "./utils/environment.js";
import { CircularProgress } from "@mui/material";

console.log(environmentUtil());

export default function App() {
  const { userData, isLoading } = useUserData();

  if (isLoading) {
    return (
      <div className="loader-container">
        <CircularProgress style={{ color: "#8F8F8F" }} size={35} />
      </div>
    );
  }

  return (
    <Routes>
      {userData ? (
        <>
          <Route element={<Layout />}>
            <Route path="*" element={<Navigate to="/dashboard" />} />
            <Route path="/dashboard" element={<Home />} />
            <Route path="/dashboard/analytics" element={<Analytics />} />
            <Route path="/dashboard/transactions" element={<Transactions />} />
            <Route path="/dashboard/collection" element={<Collection />} />
            <Route path="/dashboard/settings" element={<Settings />} />
          </Route>
        </>
      ) : (
        <>
          <React.Fragment className='MainSite'>
            <Route path="*" element={<MainSite />} />
          </React.Fragment>

          <Route path="/dashboard" element={<SignIn />} />
          <Route path="/dashboard/sign-up" element={<SignUp />} />
          <Route path="/dashboard/sign-in" element={<SignIn />} />
          <Route path="/dashboard/forgot-password" element={<ResetPassword />} />
        </>
      )}
    </Routes>
  );
}
