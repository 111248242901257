import axios from "axios";

export const fetchRecentActivity = async (address) => {
  const url = {
    method: "GET",
    url: `https://api.opensea.io/api/v2/events/accounts/${address}`,
    params: { event_type: "all", limit: "50" },
    headers: {
      accept: "application/json",
      "x-api-key": `${process.env.REACT_APP_OPENSEA_API_KEY}`,
    },
  };
  const transferUrl = {
    method: "GET",
    url: `https://api.opensea.io/api/v2/events/accounts/${address}`,
    params: { event_type: "transfer", limit: "50" },
    headers: {
      accept: "application/json",
      "x-api-key": `${process.env.REACT_APP_OPENSEA_API_KEY}`,
    },
  };

  try {
    const response = await axios.request(url);
    const data = response.data.asset_events || [];
    console.log(data);

    const transferResponse = await axios.request(transferUrl);
    const transferData = transferResponse.data.asset_events || [];

    const purchases = data.filter(
      (event) =>
        event?.event_type &&
        event?.event_type.toLowerCase() === "order" &&
        event?.maker &&
        event?.maker.toLowerCase() === address.toLowerCase()
    );

    const transfers = transferData.filter(
      (event) =>
        event?.event_type &&
        event.event_type.toLowerCase() === "transfer" &&
        event?.to_address &&
        event?.to_address.toLowerCase() === address.toLowerCase()
    );
    const sales = data.filter(
      (event) =>
        event?.event_type &&
        event.event_type.toLowerCase() === "sale" &&
        event?.seller &&
        event?.seller.toLowerCase() !== address.toLowerCase()
    );

    const activity = sales.map((event) => {
      const purchase = purchases.find(
        (p) => p?.asset && p.asset.id === event?.asset?.id
      );
      const transfer = transfers.find(
        (t) => t?.asset && t.asset.id === event?.asset?.id
      );

      const formattedEthPrice = event?.payment.quantity;
      const transactionType = "Sale";
      const buyPrice = purchase
        ? purchase?.payment.quantity
        : transfer
        ? transfer?.payment.quantity
        : "X";
      const profitPrice =
        buyPrice !== "X"
          ? parseFloat(formattedEthPrice) - parseFloat(buyPrice)
          : "X";

      return {
        ...event,
        formattedEthPrice,
        transactionType,
        buyPrice,
        profitPrice,
      };
    });

    return activity;
  } catch (error) {
    console.error(error);
    return [];
  }
};
