import React, { useState } from "react";
import { ToastContainer } from "react-toastify";
import { useUserData } from "../context/UserProvider.js";
import { useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import signOutUser from "../auth/signOut.js";

function SettingsPage() {
  const [address, setAddress] = useState("");
  const { userData, updateUserData } = useUserData();
  const navigate = useNavigate();

  const handleForm = async (event) => {
    event.preventDefault();
    setAddress(event.target.value);

    await updateUserData(userData.uid, { walletAddress: address });
  };

  const handleLogout = () => {
    signOutUser();
    window.location.reload();
  };

  return (
    <div>
      <ToastContainer
        position="bottom-center"
        theme="colored"
        draggable={false}
        pauseOnHover={false}
      />
      <div className="settingsContainer">
        <div>
          <form
            onSubmit={handleForm}
            action="#"
            method="POST"
          >
            <label htmlFor="wallet-address">Wallet Address</label>
            <input
              type="text"
              id="wallet-address"
              name="wallet-address"
              value={address}
              placeholder={userData.walletAddress || "Wallet Address"}
              onChange={(e) => setAddress(e.target.value)}
              className="input"
            />
            <button className="saveButton">Save</button>
            <button onClick={handleLogout} className="logoutButton"> {/* Use handleLogout function for onClick event */}
              Logout
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default SettingsPage;
